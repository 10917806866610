<template>
  <div style="background:#f5f5f5;">
    <h6 class="pocTit">POC申请</h6>
    <p class="pocDet">我们将根据您的需求，为您及时提供服务</p>
    <!-- poc申请表单 -->
    <div class="pocouter">
      <el-form :model="poc" ref="pocForm" :rules="pocRules" class="poc" label-width="130px">
        <el-form-item label="客户名称" prop="customerName">
          <el-input v-model="poc.customerName" clearable placeholder="请输入客户姓名"></el-input>
        </el-form-item>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="联系人" prop="contacts">
              <el-input v-model="poc.contacts" clearable placeholder="请输入联系人姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="tel">
              <el-input v-model="poc.tel" clearable placeholder="请输入手机号码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="poc.email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户数量" prop="userQty">
              <el-input v-model.number="poc.userQty" type="number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="项目概况" prop="projectContent">
          <el-input v-model="poc.projectContent" type="textarea" clearable></el-input>
        </el-form-item>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="计算层资源" prop="computerResources">
              <el-select v-model="poc.computerResources" placeholder="请选择计算层资源">
                <el-option label="Vmware Esxi" value="Vmware Esxi"></el-option>
                <el-option label="Citrix Xenserver" value="Citrix Xenserver"></el-option>
                <el-option label="超融合（品牌）" value="超融合（品牌）"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="其他计算层资源" v-show="poc.computerResources =='其他'" prop="otherComputerResources">
              <el-input v-model="poc.otherComputerResources" clearable placeholder="请输入其他计算层资源"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="访问方式" prop="accessModeList">
              <el-select v-model="poc.accessModeList" multiple placeholder="请选择访问方式">
                <el-option label="局域网" value="局域网"></el-option>
                <el-option label="互联网" value="互联网"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="应用场景" prop="appScenarioList">
              <el-select v-model="poc.appScenarioList" multiple collapse-tags placeholder="请选择应用场景">
                <el-option label="日常办公" value="日常办公"></el-option>
                <el-option label="专业图形、视频处理" value="专业图形、视频处理"></el-option>
                <el-option label="软件研发" value="软件研发"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="其他应用场景" v-show="poc.appScenarioList.indexOf('其他') != -1" prop="otherAppScenario">
              <el-input v-model="poc.otherAppScenario" clearable placeholder="请输入应用场景"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="桌面类型" prop="desktopTypeList">
              <el-select v-model="poc.desktopTypeList" multiple collapse-tags placeholder="请选择桌面类型">
                <el-option label="Win7" value="Win7"></el-option>
                <el-option label="Win10" value="Win10"></el-option>
                <el-option label="Linux" value="Linux"></el-option>
                <el-option label="麒麟" value="麒麟"></el-option>
                <el-option label="统信" value="统信"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-show="poc.desktopTypeList.indexOf('其他') != -1" label="其他桌面类型" prop="otherDesktopType">
              <el-input v-model="poc.otherDesktopType" placeholder="请输入桌面类型"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="终端类型" prop="terminalTypeList">
          <el-select v-model="poc.terminalTypeList" multiple placeholder="请选择终端类型">
            <el-option label="瘦客户机" value="瘦客户机"></el-option>
            <el-option label="传统PC" value="传统PC"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其他">
          <el-input v-model="poc.other" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button class="sub" type="primary" @click="submitPoc('pocForm')">提 交</el-button>
        <el-button class="reg" type="success" @click="showVerify">我已提交申请，马上验证下载</el-button>
      </div>
    </div>
    
    <!-- 验证码对话框 -->
      <el-dialog title="请输入提交下载申请的手机号和邮箱" :visible.sync="verifyVisible" @closed="reactive_verify" :width="`30%`">
        <div class="verify_div">
          <el-form :model="Reg" ref="Reg" class="demo-table" :rules="downloadReg">
            <el-form-item label="手机号" prop="tel">
              <el-input v-model="Reg.tel" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <!-- <el-form-item label="邮箱" prop="email">
              <el-input v-model="Reg.email" placeholder="请输入邮箱"></el-input>
            </el-form-item> -->
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="active_verify('Reg')">提交</el-button>
          </span>
      </el-dialog>

      <!-- 下载进度 -->
      <el-dialog
        title="下载进行中，请勿操作"
        :visible.sync="downloadDialogVisible"
        width="50%"
        :show-close="false"
        :before-close="downloadHandleClose">
        <div>
          <el-progress :text-inside="true" :stroke-width="24" :percentage="percentage" status="success"></el-progress>
        </div>
      </el-dialog>


  </div>
</template>

<script>
import {postPoc,getReg,postDownLoad} from '@/api'
// import axios from 'axios';

export default {
  name:"poc",
  mounted(){
    // this.$bus.$on('showPoc',this.showPoc)
    // console.log('=======',this.$route.params)
  },
  data(){
    // poc表单邮箱验证
    var eamilreg = (rule, value, callback) => {
      const regEmail =  /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regEmail.test(value)) {
        callback();
      }
      else {
        callback(new Error("请输入正确的邮箱"));
      }
    }
    // poc表单手机号验证规则
    var reg = (rule, value, callback) =>{
      var regExp = new RegExp(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/);
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的手机号码'));
      }
    }
    // poc表单用户人数验证
    var usersNumberReg = (rule, value, callback) => {
      if(value < 1){
        callback(new Error('用户人数最少为1人'));
      }else{
        callback()
      }
    }
    return{
      downloadDialogVisible: false,
      percentage:0,
      // poc申请数据
      poc:{
        // 用户名称
        customerName:'',
        // 联系人
        contacts:'',
        // 电话
        tel:'',
        // 邮箱
        email:'',
        // 用户数量
        userQty:1,
        // 项目概况
        projectContent:'',
        // 计算层资源
        computerResources:'',
        // 计算层资源(其他)
        otherComputerResources:'',
        // 访问方式
        accessModeList:[],
        // 应用场景
        appScenarioList:[],
        // 其他应用场景
        otherAppScenario:'',
        // 桌面类型
        desktopTypeList:[],
        // 其他桌面类型
        otherDesktopType:'',
        // 终端类型
        terminalTypeList:[],
        // 其他
        other:''
      },
      // poc申请表单验证规则 
      pocRules:{
        customerName:[
          {required: true, message: '请输入用户名称', trigger: 'blur'}
        ],
        contacts:[
          {required: true, message: '请输入联系人', trigger: 'blur'}
        ],
        tel:[
          {required: true, message: '请输入电话号码', trigger: 'blur'},
          {validator:reg,trigger: 'blur'}
        ],
        userQty:[
          { required: true, message: '用户人数不能为空',trigger: 'blur'},
          {validator:usersNumberReg,trigger: 'blur'}
        ],
        email:[
          { required: true, message: '邮箱不能为空',trigger: 'blur'},
          { validator: eamilreg, trigger: "blur" }
        ],
        projectContent:[
          { required: true, message: '请输入项目概述'}
        ],
        computerResources:[
          { required: true, message: '请选择计算层资源'}
        ],
        otherComputerResources:[
          // { required: true, message: '请输入您使用的计算层资源'},
          // {validator: otherComputerResourcesreg,trigger:'blur'}
        ],
        accessModeList:[
          { required: true, message: '请选择访问方式'},
        ],
        appScenarioList:[
          { required: true, message: '请选择应用场景'},
        ],
        otherAppScenario:[
          // { required: true, message: '请输入应用场景'},
        ],
        desktopTypeList:[
          { required: true, message: '请选择桌面类型'},
        ],
        otherDesktopType:[
          // {required: true, message: '请输入其他桌面类型'}
        ],
        terminalTypeList:[
          {required: true, message: '请选择终端类型'}
        ]
      },
      verifyVisible:false,
      //下载前验证
        Reg:{
          tel:'',
        },
        downloadReg:{
          tel:[
            {required: true, message: '请输入电话号码', trigger: 'blur'},
            {validator:reg,trigger: 'blur'}
          ],
          /* email:[
            { required: true, message: '邮箱不能为空',trigger: 'blur'},
            { validator: eamilreg, trigger: "blur" }
          ], */
        },
    }
  },
  methods:{
    // 提交申请
    submitPoc(pocForm){
      let that = this
      this.$refs[pocForm].validate(async (valid) => {
        if (valid) {
          const data = this.poc
          let result = await postPoc(data);
          if (result.data == 1) {
            this.pocFormVisible = false;
            this.$message({message: '提交成功！',type: 'success'})
            this.poc = {
              // 用户名称
              customerName:'',
              // 联系人
              contacts:'',
              // 电话
              tel:'',
              // 邮箱
              email:'',
              // 用户数量
              userQty:1,
              // 项目概况
              projectContent:'',
              // 计算层资源
              computerResources:'',
              // 计算层资源(其他)
              otherComputerResources:'',
              // 访问方式
              accessModeList:[],
              // 应用场景
              appScenarioList:[],
              // 其他应用场景
              otherAppScenario:'',
              // 桌面类型
              desktopTypeList:[],
              // 其他桌面类型
              otherDesktopType:'',
              // 终端类型
              terminalTypeList:[],
              // 其他
              other:''
            }
            setTimeout(() => {
              that.$router.go(-1)
            }, 1000);
          }else if(result.data == 2){
            this.$message({message: '此手机号已经提交过poc申请，请勿重新提交',type: 'error'})
          }else{
            this.$message.error('提交失败!')
          }
        } else {
          this.$message.error('请正确填写内容，*为必填项')
          return false;
        }
      });
    },
    showVerify(){
      this.verifyVisible = true
      // this.startDown()
    },
    reactive_verify(){
      this.Reg = {tel:'',email:''}
    },
    active_verify(pocForm){
      this.$refs[pocForm].validate(async (valid) => {
        if (valid) {
          const result = await getReg(this.Reg.tel)
          if(result.data[0]){
            if(result.data[0].status == 2){
              this.$message.success('验证成功，下载即将开始')
              this.verifyVisible = false
              this.startDown()
              let temp = {
                downloadId:this.$route.params.id,
                phone:this.Reg.tel,
              }
              postDownLoad(temp)
              /* 存储手机号 */
              localStorage.setItem('phone',this.Reg.tel);
              this.Reg = {
                tel:'',
              }

              

            }else if(result.data[0].status == 0 || result.data[0].status == 1){
              this.$message.info('您的申请仍在审核中，请关注申请进度')
            }else{
              this.$message.error('您的申请未通过，请重新申请')
              this.verifyVisible = false
            }
          }else{
            /* 查询失败 */
            this.$confirm('您输入的信息有误', '', {
              confirmButtonText: '提交POC申请',
              cancelButtonText: '重新输入',
              type: 'warning'
            }).then(() => {
              // this.$bus.$emit('showPoc')
              this.verifyVisible = false
            }).catch(() => {
              // 重新输入
            });
          }
        } else {
          this.$message.error('请正确填写内容，*为必填项')
          return false;
        }
      });
    },
    // 下载过程
    startDown(){
        window.location.href=`${window.ipConfig.downloadUrl}api/down/download?filePath=upload//${this.$route.params.name}&fileName=${this.$route.params.name}`
      setTimeout(() => {
        this.$router.go(-1)
      },1000)
      /* this.downloadDialogVisible = true
      let that = this
      axios({
        method:'post',
        url:'/api/down/download',
        data:{  
          url:'C:\\panshiWeb\\upload\\' + that.$route.params.name
        },
        responseType:'blob',
        transformRequest: [function (data) {
          let ret = ''
          for (let it in data) {
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
          }
          return ret
        }],
        // 进度条
        onDownloadProgress: function (progressEvent) {//axios封装的原生获取下载进度的事件，该回调参数progressEvent中包含下载文件的总进度以及当前进度
        if (progressEvent.lengthComputable) {
            that.percentage = Math.floor(progressEvent.loaded / progressEvent.total * 100)  //实时获取最新下载进度
          }
        }
        
      })
      .then(res => {
        setTimeout(() => {
          this.downloadDialogVisible = false
          this.percentage = 0
        }, 5000);

        const content = res.data
        const blob = new Blob([content])
        if ('download' in document.createElement('a')) {
          const elink = document.createElement('a')
          elink.download = that.$route.params.name
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, that.$route.params.name)
        }
        
      }) */
    },
    downloadHandleClose(){
      this.$message.info('下载进行中请勿操作')
    }
      
  },
  computed:{
    window: function () {
      // `this` 指向 vm 实例
      return window
    }
  },
}
</script>

<style scoped>
  .pocTit{font-size: 34px;padding: 30px 0px;text-align: center;}
  .pocDet{font-size:16px;color:#888;text-align: center;}
  .pocouter{width: 900px;margin:30px auto 0px;background: #fff;padding: 40px 20px;}
  .poc .el-select{width: 100%;}
  .poc .el-form-item__label{font-size:16px;}
  .poc .el-input__inner{border-radius: 20px;}
  .poc .el-textarea__inner{border-radius: 20px !important;}
  .sub{width: 300px;border-radius: 30px;font-size: 18px;display: block;margin: 0 auto;}
  .reg{width: 300px;border-radius: 30px;font-size: 18px;display: block;margin: 20px auto 0px;}
  >>> .verify_div .el-input__inner{border-radius:20px;}
</style>